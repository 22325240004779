import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHome } from 'models/home';
import { useLanguage } from 'models/i18n';
import Link from '../Link';
import styles from './index.css';

const DonateButton = ({ className }) => {
	const { t } = useTranslation(['common']);
	const [{ donateEn }] = useHome();
	const [{ lan }] = useLanguage();

	return (
		<Link
			url={lan === 'en' ? donateEn.url || '/donate/support-migrants' : '/donate/support-migrants'}
			className={classnames(styles.donateButton, className)}
		>
			{lan === 'en' ? donateEn.button || t('donate') : t('donate')}
		</Link>
	);
};

export default DonateButton;
