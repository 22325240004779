import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeCulture } from 'models/culture';

export default {
	path: '/culture',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'culture' */ './component')],
			render: ([Culture]) => <Culture />,
			onEnter: async ({ store }) => {
				console.log('on Enter culture');
				await store.dispatch(initializeCulture());
				await store.dispatch(storeActivePage('culture'));
				console.log('on Enter culture / end');
			},
		},
	],
};
