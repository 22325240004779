import { createAction } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const initializeAppPrivacyPolicyAndTerms = createAction(
	'INITIALIZE_APP_PRIVACY_POLICY_AND_TERMS',
	() => async dispatch => {
		await dispatch(setPageLoading());
	},
);

const selectAppPrivacyPolicyAndTerms = state => ({
	...state.appPrivacyPolicyAndTerms,
});

export const useAppPrivacyPolicyAndTerms = () => useRedux(selectAppPrivacyPolicyAndTerms);
