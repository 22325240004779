import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeVideoModules } from 'models/videomodules';

export default {
	path: '/videomodules',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'videomodules' */ './component')],
			render: ([VideoModules]) => <VideoModules />,
			onEnter: async ({ store }) => {
				console.log('on Enter videomodules');
				await store.dispatch(initializeVideoModules());
				await store.dispatch(storeActivePage('videomodules'));
				console.log('on Enter videomodules / end');
			},
		},
	],
};
