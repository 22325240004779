import React from 'react';
import cn from 'classnames';
import { useCommon } from 'models/common';
import { NavL1Controller, NavVariant } from 'components/organisms/NavL1Controller';
import styles from './index.css';

function Navbar({ className, children, activeL0 = false }) {
	const [commonData] = useCommon();
	const navRef = React.useRef(null);

	const [activeL1Id, setActiveL1Id] = React.useState(-1);
	const createDesktopOnMouseLeave = () => () => {
		const isDesktop = window.matchMedia('(min-width: 1280px)').matches;
		if (isDesktop) setActiveL1Id(-1);
	};
	const createDesktopOnMouseOver = id => () => {
		const isDesktop = window.matchMedia('(min-width: 1280px)').matches;
		if (isDesktop) setActiveL1Id(id);
	};
	const createMobileOnMouseClick = id => () => {
		const isMobile = window.matchMedia('(max-width: 1279px)').matches;
		if (isMobile) setActiveL1Id(prev => (prev === id ? -1 : id));
	};
	const isActiveL1 = item => item.id === activeL1Id;
	const isDonateLink = item => item.type === NavVariant.DONATE_LINK;
	const isNotDonateLink = item => !isDonateLink(item);

	React.useEffect(() => {
		// reset activeL1 when parent inactive
		if (!activeL0) setActiveL1Id(-1);
	}, [activeL0]);

	React.useEffect(() => {
		// 桌機版，連結點選時關閉選單（手機版由 Header 元件控制）
		function callback(event) {
			const element = event.target.tagName;
			const href = event.target.getAttribute('href');
			const isDesktop = window.matchMedia('(min-width: 1280px)').matches;
			if (isDesktop && element === 'A' && !!href) {
				setActiveL1Id(-1);
			}
		}

		if (navRef.current) {
			navRef.current.addEventListener('click', callback);
		}

		return () => {
			if (navRef.current) {
				navRef.current.removeEventListener('click', callback);
			}
		};
	}, [navRef.current, setActiveL1Id]);

	return (
		<nav
			className={cn(styles.root, activeL0 && styles.active, className)}
			onMouseLeave={createDesktopOnMouseLeave()}
			ref={navRef}
		>
			{/* 一般按鈕 */}
			{(commonData?.navLinks ?? []).filter(isNotDonateLink).map(navL1 => (
				<NavL1Controller
					key={navL1.id}
					navL1={navL1}
					activeL1={isActiveL1(navL1)}
					onMouseOver={createDesktopOnMouseOver(navL1.id)}
					onClick={createMobileOnMouseClick(navL1.id)}
				/>
			))}
			{/* 捐款按鈕 */}
			<div className={styles.donateLinkBlock}>
				{(commonData?.navLinks ?? []).filter(isDonateLink).map(navL1 => (
					<NavL1Controller
						key={navL1.id}
						navL1={navL1}
						activeL1={isActiveL1(navL1)}
						onMouseOver={createDesktopOnMouseOver(navL1.id)}
						onClick={createMobileOnMouseClick(navL1.id)}
					/>
				))}
			</div>
			{children}
		</nav>
	);
}

export { Navbar };
