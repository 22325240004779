import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeBlogDetail } from 'models/blog/blogDetail';

export default {
	path: '/:id',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'blogDetail' */ './component')],
			render: ([BlogDetail]) => <BlogDetail />,
			onEnter: async ({ store, params: { id } }) => {
				console.log('on Enter blogDetail');
				await store.dispatch(initializeBlogDetail(id));
				await store.dispatch(storeActivePage(`blog_${id}`));
				console.log('on Enter blogDetail / end');
			},
		},
	],
};
