import React from 'react';

// v5 24x24
function PlusIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
			<path style={{ fill: 'none' }} d="M0 0h24v24H0z" />
			<path
				data-name="↳Color"
				d="M14 8H8v6H6V8H0V6h6V0h2v6h6z"
				transform="translate(5 5)"
				style={{ fill: '#f5c948' }}
			/>
		</svg>
	);
}

export { PlusIcon };
