import React from 'react';

function MinusIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
			<g data-name="-">
				<path style={{ fill: 'none' }} d="M0 0h24v24H0z" />
				<path
					data-name="Rectangle 1738"
					transform="translate(5 10.5)"
					style={{ fill: '#fff' }}
					d="M0 0h14v3H0z"
				/>
			</g>
		</svg>
	);
}

export { MinusIcon };
