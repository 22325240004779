import React from 'react';
import { storeActivePage } from 'models/common';
import { initializePicAndVideoModules } from 'models/picandvideomodules';

export default {
	path: '/picandvideomodules',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'picandvideomodules' */ './component')],
			render: ([PicAndVideoModules]) => <PicAndVideoModules />,
			onEnter: async ({ store }) => {
				console.log('on Enter picandvideomodules');
				await store.dispatch(initializePicAndVideoModules());
				await store.dispatch(storeActivePage('picandvideomodules'));
				console.log('on Enter picandvideomodules / end');
			},
		},
	],
};
