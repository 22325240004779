import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeMentor } from 'models/mentor';

export default {
	path: '/mentor',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'mentor' */ './component')],
			render: ([Mentor]) => <Mentor />,
			onEnter: async ({ store }) => {
				console.log('on Enter mentor');
				await store.dispatch(initializeMentor());
				await store.dispatch(storeActivePage('mentor'));
				console.log('on Enter mentor / end');
			},
		},
	],
};
