import { historyPush } from 'models/routing';
import { setPageLoading } from 'models/common';
import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

const resetDonationState = createAction('RESET_DONATION_STATE');

export const fetchDonationInfo = createAction(
	'FETCH_DONATION_INFO',
	event_url => async dispatch => {
		const { status, message, data } = await wrapFetch(`donation/event/${event_url}`, {
			method: 'GET',
		});

		if (status !== 200) {
			console.error(message);
		}

		if (!data) {
			dispatch(historyPush({ pathname: '/' }));
		}

		return data;
	},
);

export const initializeDonationInfo = createAction(
	'INITIALIZE_DONATION_INFO',
	event_url => async dispatch => {
		dispatch(resetDonationState());
		await dispatch(fetchDonationInfo(event_url));
		await dispatch(setPageLoading());
	},
);

const reducer = {
	donationInfo: handleActions(
		{
			FETCH_DONATION_INFO_FULFILLED: (state, action) => ({
				...state,
				banner: action.payload.banner,
				quotes: action.payload.messages,
				faqs: action.payload.questions,
				modules: action.payload.modules,
			}),
		},
		{
			banner: {},
			quotes: [],
			faqs: [],
			modules: [],
		},
	),
};

const selectDonationInfo = state => ({
	...state.donationInfo,
});

export const useDonationInfo = () => useRedux(selectDonationInfo);

export default { reducer };
