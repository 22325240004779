import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeBrandBook } from 'models/brandBook';

export default {
	path: '/brand-book',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'brand-book' */ './component')],
			render: ([BrandBook]) => <BrandBook />,
			onEnter: async ({ store }) => {
				console.log('on Enter brand-book');
				await store.dispatch(initializeBrandBook());
				await store.dispatch(storeActivePage('brand-book'));
				console.log('on Enter brand-book / end');
			},
		},
	],
};
