import React from 'react';
import { NavItem, NavItemVariant } from 'components/atoms/NavItem';
import cn from 'classnames';
import styles from './index.css';

// type NavItem = {
// 	id: number;
// 	type?: 'ONE_COLUMN' | 'TWO_COLUMNS' | 'LINK' | 'DONATE_LINK',
// 	name: string;
// 	link: string;
// 	hightlight?: 'Y' | 'N',
// 	sub?: NavItem[]
// }

// navL2: NavItem[]

const NavVariant = {
	ONE_COLUMN: 'ONE_COLUMN',
	TWO_COLUMNS: 'TWO_COLUMNS',
	LINK: 'LINK',
	DONATE_LINK: 'DONATE_LINK',
};

function NavOneColumnMenu({ navL2, className, activeL1 = false, ...props }) {
	const isLinkL2 = item => item?.highlight !== 'Y';
	const isHighlightL2 = item => item?.highlight === 'Y';

	return (
		// 控制選單展開（避免設置 padding 在這層，max-height 無法控制 padding）
		<div
			className={cn(styles.root, styles.variantOneColumn, activeL1 && styles.active, className)}
			{...props}
		>
			{/* 選單排版 */}
			<div className={cn(styles.content, !navL2.some(isHighlightL2) && styles.edgeBottom)}>
				{navL2.filter(isLinkL2).map(item => (
					<NavItem key={item.id} href={item.link} variant={NavItemVariant.LINK_L2}>
						{item.name}
					</NavItem>
				))}
				{navL2.filter(isHighlightL2).map(item => (
					<NavItem key={item.id} href={item.link} variant={NavItemVariant.HIGHLIGHT_LINK_L2}>
						{item.name}
					</NavItem>
				))}
			</div>
		</div>
	);
}

function NavTwoColumnsMenu({ navL2, className, activeL1 = false, ...props }) {
	const [activeL2Index, setActiveL2Index] = React.useState(0);

	const createDesktopMouseOverHandler = index => () => {
		const isDesktop = window.matchMedia('(min-width: 1280px)').matches;
		if (isDesktop) setActiveL2Index(index);
	};
	const createMobileMouseClickHandler = index => () => {
		const isMobile = window.matchMedia('(max-width: 1279px)').matches;
		if (isMobile) setActiveL2Index(prev => (prev === index ? -1 : index));
	};
	const isActiveL2 = (_item, index) => index === activeL2Index;

	React.useEffect(() => {
		// reset activeL2 when parent inactive
		if (!activeL1) setActiveL2Index(0);
	}, [activeL1]);

	return (
		<div
			className={cn(styles.root, styles.variantTwoColumn, activeL1 && styles.active, className)}
			{...props}
		>
			<div className={styles.contentL2}>
				{/* L2 */}
				<div className={styles.blockL2}>
					{navL2.map((item, index) => (
						<div key={item.id}>
							<NavItem
								variant={NavItemVariant.TRIGGER_L2}
								active={isActiveL2(item, index)}
								onMouseOver={createDesktopMouseOverHandler(index)}
								onClick={createMobileMouseClickHandler(index)}
							>
								{item.name}
							</NavItem>
							{/* L3 Mobile 避免 padding 加在 max-height 控制層 */}
							<div className={cn(styles.mobileBlockL3, isActiveL2(item, index) && styles.activeL2)}>
								<div className={styles.mobileBlockL3Content}>
									{item.sub.map(itemL3 => (
										<NavItem key={itemL3.id} href={itemL3.link} variant={NavItemVariant.LINK_L3}>
											{itemL3.name}
										</NavItem>
									))}
								</div>
							</div>
						</div>
					))}
				</div>
				{/* Separator Desktop */}
				<div className={styles.desktopSeparator} />
				{/* L3 Desktop */}
				<div className={styles.desktopBlockL3}>
					{(navL2.find(isActiveL2)?.sub ?? []).map(item => (
						<NavItem key={item.id} href={item.link} variant={NavItemVariant.LINK_L3}>
							{item.name}
						</NavItem>
					))}
				</div>
			</div>
		</div>
	);
}

function NavMenu({
	variant = NavVariant.ONE_COLUMN,
	navL2,
	className,
	activeL1 = false,
	...props
}) {
	return (
		<>
			{variant === NavVariant.ONE_COLUMN && (
				<NavOneColumnMenu navL2={navL2} className={className} activeL1={activeL1} {...props} />
			)}
			{variant === NavVariant.TWO_COLUMNS && (
				<NavTwoColumnsMenu navL2={navL2} className={className} activeL1={activeL1} {...props} />
			)}
		</>
	);
}

export { NavMenu, NavVariant };
