import React from 'react';
import { initializeDonateWelcome } from 'models/donate/welcome';
import { storeActivePage } from 'models/common';

export default {
	path: '/welcome',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'donateWelcome' */ './component')],
			render: ([DonateWelcome]) => <DonateWelcome />,
			onEnter: async ({ store }) => {
				console.log('on Enter donate welcome');
				await store.dispatch(initializeDonateWelcome());
				await store.dispatch(storeActivePage('donate/welcome'));
				console.log('on Enter donate welcome / end');
			},
		},
	],
};
