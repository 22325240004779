import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchBrandBook = createAction('FETCH_BRANDBOOK', () => async () => {
	const { status, message, data } = await wrapFetch('brandbook', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeBrandBook = createAction('INITIALIZE_BRANDBOOK', () => async dispatch => {
	await dispatch(fetchBrandBook());
	await dispatch(setPageLoading());
});

const reducer = {
	brandBook: handleActions(
		{
			FETCH_BRANDBOOK_FULFILLED: (state, action) => ({
				...state,
				banner: action.payload.banner,
				files: action.payload.files,
				cta: action.payload.cta,
			}),
		},
		{
			banner: {},
			files: [],
			cta: [],
		},
	),
};

const selectBrandBook = state => ({
	...state.brandBook,
});

export const useBrandBook = () => useRedux(selectBrandBook);

export default { reducer };
