import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchCulture = createAction('FETCH_CULTURE', () => async () => {
	const { status, message, data } = await wrapFetch('culture', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeCulture = createAction('INITIALIZE_CULTURE', () => async dispatch => {
	await dispatch(fetchCulture());
	await dispatch(setPageLoading());
});

const reducer = {
	culture: handleActions(
		{
			FETCH_CULTURE_FULFILLED: (state, action) => ({
				...state,
				title: action.payload.title,
				subtitle: action.payload.subtitle,
				cultureContent: action.payload.content,
				blocks: action.payload.blocks,
				cta: action.payload.cta,
			}),
		},
		{
			title: '',
			subtitle: '',
			cultureContent: [],
			blocks: [],
			cta: [],
		},
	),
};

const selectCulture = state => ({
	...state.culture,
});

export const useCulture = () => useRedux(selectCulture);

export default { reducer };
