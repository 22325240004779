import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchProject = createAction('FETCH_PROJECT', id => async () => {
	const { status, message, data } = await wrapFetch(`template/${id}`, {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeProject = createAction('INITIALIZE_PROJECT', id => async dispatch => {
	await dispatch(fetchProject(id));
	await dispatch(setPageLoading());
});

const reducer = {
	project: handleActions(
		{
			FETCH_PROJECT_FULFILLED: (state, action) => ({
				...state,
				metaDescription: action.payload.meta_description,
				title: action.payload.banner,
				intro:
					typeof action.payload.content === 'string'
						? [{ type: 'string', content: action.payload.content }]
						: action.payload.content,
				sliderOne: action.payload.slider_a,
				imageWall: action.payload.photo_wall,
				sliderTwo: action.payload.slider_b,
				video: {
					url: action.payload.video_url,
					title: action.payload.video_title,
					len: action.payload.video_len,
					pic: action.payload.video_pic,
				},
				ctas: action.payload.cta,
			}),
		},
		{
			metaDescription: '',
			title: {},
			intro: [],
			sliderOne: [],
			imageWall: [],
			sliderTwo: [],
			video: {},
			ctas: [],
		},
	),
};

const selectProject = state => ({
	...state.project,
});

export const useProject = () => useRedux(selectProject);

export default { reducer };
