import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeHalfText } from 'models/halftext';

export default {
	path: '/halftext',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'halftext' */ './component')],
			render: ([HalfText]) => <HalfText />,
			onEnter: async ({ store }) => {
				console.log('on Enter halftext');
				await store.dispatch(initializeHalfText());
				await store.dispatch(storeActivePage('halftext'));
				console.log('on Enter halftext / end');
			},
		},
	],
};
