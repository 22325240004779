import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeDonateForm } from 'models/donate/form';
import { initializeDonateInfo } from 'models/donate/info';
import SuccessRoute from './Success';
import WelcomeRoute from './Welcome';

export default {
	path: '/donate',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'donateForm' */ './component')],
			render: ([DonateForm]) => <DonateForm />,
			onEnter: async ({ store }) => {
				console.log('on Enter donate');
				await store.dispatch(initializeDonateForm());
				await store.dispatch(initializeDonateInfo());
				await store.dispatch(storeActivePage('donate'));
				console.log('on Enter donate / end');
			},
		},
		SuccessRoute,
		WelcomeRoute,
	],
};
