import { z } from 'zod';

const seoDataSchema = z.object({
	url: z.string(),
	meta_title: z.string(),
	meta_description: z.string(),
	meta_image: z.string(),
	og_title: z.string(),
	og_description: z.string(),
	og_image: z.string(),
	json_ld: z.string(),
});

export { seoDataSchema };
