import React from 'react';
import cn from 'classnames';
import styles from './index.css';

function ChevronDown({ className }) {
	return (
		<div className={cn(styles.root, className)}>
			<span />
			<span />
		</div>
	);
}

export { ChevronDown };
