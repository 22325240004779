import { setPageLoading } from '../common';
import { historyPush } from 'models/routing';
import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

const resetImpactEventState = createAction('RESET_IMPACT_STATE');
export const fetchImpactEvent = createAction('FETCH_IMPACT_EVENT', event => async dispatch => {
	const { status, message, data } = await wrapFetch(`impact/${event}`, {
		method: 'GET',
	});

	if (status !== 200) {
		console.error(message);
	}

	if (!data) {
		dispatch(historyPush({ pathname: '/' }));
	}

	return data;
});

export const initializeImpactEvent = createAction(
	'INITIALIZE_IMPACT_EVENT',
	event => async dispatch => {
		await dispatch(resetImpactEventState());
		await dispatch(fetchImpactEvent(event));
		await dispatch(setPageLoading());
	},
);

const reducer = {
	impactEvent: handleActions(
		{
			FETCH_IMPACT_EVENT_FULFILLED: (state, action) => ({
				...state,
				title: action.payload.title,
				pic: action.payload.pic,
				pic_mobile: action.payload.pic_mobile,
				blocks: action.payload.blocks,
				cta: action.payload.cta,
			}),
		},
		{
			title: '',
			pic: '',
			pic_mobile: '',
			blocks: [],
			cta: [],
		},
	),
};

const selectImpactEvent = state => ({
	...state.impactEvent,
});

export const useImpactEvent = () => useRedux(selectImpactEvent);

export default { reducer };
