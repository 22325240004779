import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouting } from 'models/routing';
import { SeoProvider } from 'components/organisms/SeoProvider';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import { changeLng } from 'util/i18n';
import styles from './index.css';

const App = ({ children }) => {
	const { t } = useTranslation(['header', 'footer']);
	const [{ pathname }] = useRouting();
	const lan = pathname.split('/')[1] || 'tw';

	useEffect(() => {
		changeLng(lan);
	}, [lan]);

	return (
		<div>
			<SeoProvider>
				<Header t={t('header:header', { returnObjects: true })} />
				<div className={styles.children}>{children}</div>
				<Footer t={t('footer:footer', { returnObjects: true })} />
			</SeoProvider>
		</div>
	);
};

export default App;
