import React from 'react';
import Link from 'components/atoms/Link';
import cn from 'classnames';
import { PlusIcon, MinusIcon, ChevronDown } from 'components/atoms/Icon';
import styles from './index.css';

const NavItemVariant = {
	LINK_L1: 'variantLinkL1',
	DONATE_LINK_L1: 'variantDonateLinkL1',
	TRIGGER_L1: 'variantTriggerL1',
	TRIGGER_L2: 'variantTriggerL2',
	LINK_L2: 'variantLinkL2',
	HIGHLIGHT_LINK_L2: 'variantHighlightLinkL2',
	LINK_L3: 'variantLinkL3',
};

function NavItem({
	href = '#',
	children,
	variant = NavItemVariant.LINK_L1,
	active = false,
	className,
	...props
}) {
	const linkObject = generateLinkObject(href);

	return (
		<Link
			to={linkObject.to}
			url={linkObject.url}
			className={cn(styles.root, styles?.[variant], active && styles.active, className)}
			{...props}
		>
			{children}
			{(variant === NavItemVariant.TRIGGER_L1 || variant === NavItemVariant.TRIGGER_L2) && (
				<>
					<ChevronDown id="i1" className={cn(styles.chevronIcon)} />
					<MinusIcon id="i2" className={cn(styles.minusIcon)} />
					<PlusIcon id="i3" className={cn(styles.plusIcon)} />
				</>
			)}
		</Link>
	);
}

function isExternalLink(origin) {
	// FIXME: workaround 作法，不確定本專案 ENV 傳入方式，暫時寫死
	if (location?.hostname === 'localhost') {
		return !'https://oneforty.kiitzu.ninja'.startsWith(origin);
	}

	return !location?.origin.startsWith(origin);
}

function generateLinkObject(href) {
	try {
		const urlObject = new URL(href);

		// FIXME: workaround 去掉 /tw 語系路徑以符合 Link 元件設計，應該統一整合這個功能
		return {
			to: isExternalLink(urlObject.origin)
				? ''
				: `${urlObject.pathname}${urlObject.search}`.replace('/tw', ''),
			url: isExternalLink(urlObject.origin) ? href : '',
		};
	} catch (error) {
		return {
			to: '',
			url: '',
		};
	}
}

export { NavItemVariant, NavItem };
