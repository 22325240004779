import React from 'react';
import classnames from 'classnames';
import LoadingGIF from 'images/common/loading.gif';
import styles from './index.css';

const Loading = ({ className }) => (
	<div className={classnames(styles.loading, className)}>
		<img className={styles.image} src={LoadingGIF} alt="loading..." />
	</div>
);

export default Loading;
