import React, { Suspense } from 'react';

import App from 'layouts/App';
import Loading from 'layouts/Loading';

import { HistoryContext } from 'models/routing';

import useRouter from 'util/hook/useRouter';
import { useCommon } from 'models/common';

const Router = ({ routes, history, store }) => {
	const { component } = useRouter(routes, history, store);
	const [{ isLoading }] = useCommon();

	if (isLoading) return <Loading />;
	return (
		<HistoryContext.Provider value={history}>
			<Suspense fallback={null}>
				<App>
					<Suspense fallback={null}>{component}</Suspense>
				</App>
			</Suspense>
		</HistoryContext.Provider>
	);
};

export default Router;
