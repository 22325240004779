import { setPageLoading } from 'models/common';
import { historyPush } from 'models/routing';
import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

const resetBlogState = createAction('RESET_BLOG_STATE');

export const fetchBlog = createAction('FETCH_BLOG', id => async dispatch => {
	const { status, message, data } = await wrapFetch(`post/${id}`, {
		method: 'GET',
	});

	if (status !== 200) {
		console.error(message);
	}

	if (!data) {
		dispatch(historyPush({ pathname: '/' }));
	}

	return data;
});

export const initializeBlogDetail = createAction('INITIALIZE_BLOG_DETAIL', id => async dispatch => {
	dispatch(resetBlogState());
	await dispatch(fetchBlog(id));
	await dispatch(setPageLoading());
});

const initialState = {
	title: { title: '', pic: '' },
	content: '',
	links: [],
	metaDescription: '',
};

const reducer = {
	blogDetail: handleActions(
		{
			RESET_BLOG_STATE: () => ({
				...initialState,
			}),
			FETCH_BLOG_FULFILLED: (state, action) => ({
				...state,
				title: { title: action.payload.title, pic: action.payload.pic },
				content: action.payload.content,
				links: action.payload.post_links,
				metaDescription: action.payload.meta_description,
			}),
		},
		initialState,
	),
};

const selectBlogDetail = state => ({
	...state.blogDetail,
});

export const useBlogDetail = () => useRedux(selectBlogDetail);

export default { reducer };
