import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeBanner } from 'models/banner';

export default {
	path: '/banner',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'banner' */ './component')],
			render: ([Banner]) => <Banner />,
			onEnter: async ({ store }) => {
				console.log('on Enter banner');
				await store.dispatch(initializeBanner());
				await store.dispatch(storeActivePage('banner'));
				console.log('on Enter banner / end');
			},
		},
	],
};
