import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useRouting } from 'models/routing';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';

const { SELF_HOST_ENDPOINT } = process.env;

const SEO = ({
	url = '',
	metaTitle = '',
	metaDescription = '',
	metaImage = '',
	ogTitle = '',
	ogDescription = '',
	ogImage = '',
	jsonLd = '',
}) => {
	const { t } = useTranslation(['common']);
	const [{ pathname }] = useRouting();
	const { setPathPageView } = useTrackingCode();

	const helmet = {
		url: url || `${SELF_HOST_ENDPOINT}${pathname}`,
		meta_title: metaTitle || t('meta.title'),
		meta_description: metaDescription || t('meta.description'),
		meta_image: metaImage || `${SELF_HOST_ENDPOINT}/meta-image.jpg`,
		og_title: ogTitle || t('meta.title'),
		og_description: ogDescription || t('meta.description'),
		og_image: ogImage || `${SELF_HOST_ENDPOINT}/meta-image.jpg`,
		json_ld: jsonLd,
		// FIXME: 不確定此屬性，暫時沿用舊有代碼
		appleTouchIcon: `${SELF_HOST_ENDPOINT}/apple-touch-icon.png`,
	};

	// resize image
	const width = 500;
	const [height, setHeight] = useState(500);
	useEffect(() => {
		const img = new Image();
		img.addEventListener('load', () => {
			setHeight(parseInt((img.naturalHeight / img.naturalWidth) * width, 10));
		});
		img.src = helmet.meta_image;
	}, []);

	// Hubspot Tracking Code
	useEffect(() => {
		document.title = helmet.meta_title;
		setPathPageView(pathname);
	}, [pathname]);

	return (
		<Helmet title={helmet.meta_title}>
			<meta name="description" content={helmet.meta_description} />
			<meta name="image" content={helmet.meta_image} />
			<meta property="og:url" content={helmet.url} />
			<meta property="og:title" content={helmet.og_title} />
			<meta property="og:description" content={helmet.og_description} />
			<meta property="og:image" content={helmet.og_image} />
			<meta property="og:image:secure_url" content={helmet.og_image} />
			<meta property="og:image:width" content={width} />
			<meta property="og:image:height" content={height} />
			<link rel="apple-touch-icon" href={helmet.appleTouchIcon} />
			<script type="application/ld+json">{helmet.json_ld}</script>
		</Helmet>
	);
};

export default SEO;
