import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeTeam } from 'models/team';

export default {
	path: '/team',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'team' */ './component')],
			render: ([Team]) => <Team />,
			onEnter: async ({ store }) => {
				console.log('on Enter team');
				await store.dispatch(initializeTeam());
				await store.dispatch(storeActivePage('team'));
				console.log('on Enter team / end');
			},
		},
	],
};
