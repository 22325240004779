import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchMentor = createAction('FETCH_MENTOR', () => async () => {
	const { status, message, data } = await wrapFetch('mentor', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeMentor = createAction('INITIALIZE_MENTOR', () => async dispatch => {
	await dispatch(fetchMentor());
	await dispatch(setPageLoading());
});

const reducer = {
	mentor: handleActions(
		{
			FETCH_MENTOR_FULFILLED: (state, action) => ({
				...state,
				banner: action.payload.banner,
				mentors: action.payload.mentors,
				cta: action.payload.cta,
			}),
		},
		{
			banner: {},
			mentors: [],
			cta: [],
		},
	),
};

const selectMentor = state => ({
	...state.mentor,
});

export const useMentor = () => useRedux(selectMentor);

export default { reducer };
