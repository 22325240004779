import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeFeatureCards } from 'models/featurecards';

export default {
	path: '/featurecards',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'featurecards' */ './component')],
			render: ([FeatureCards]) => <FeatureCards />,
			onEnter: async ({ store }) => {
				console.log('on Enter featurecards');
				await store.dispatch(initializeFeatureCards());
				await store.dispatch(storeActivePage('featurecards'));
				console.log('on Enter featurecards / end');
			},
		},
	],
};
