import { createAction, handleActions } from 'redux-actions';
import { wrapLangFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchHome = createAction('FETCH_HOME', () => async () => {
	const { status, message, data } = await wrapLangFetch('homepage', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeHome = createAction('INITIALIZE_HOME', () => async dispatch => {
	await dispatch(fetchHome());
	await dispatch(setPageLoading());
});

const reducer = {
	home: handleActions(
		{
			FETCH_HOME_FULFILLED: (state, action) => ({
				...state,
				banner: {
					title: action.payload.title,
					video: action.payload.video,
					video_mobile: action.payload.video_mobile,
				},
				intro: action.payload.content,
				semiCtas: action.payload.blocks,
				impact: {
					title: action.payload.number.title,
					text: action.payload.number.content,
					numbers: action.payload.number.data,
				},
				image: {
					desktop: action.payload.pic,
					mobile: action.payload.pic_mobile,
				},
				fullCta: action.payload.block,
				ctas: action.payload.cta,
				CTAButton: {
					navi_button: action.payload.navi_button,
					navi_url: action.payload.navi_url,
				},
				donateEn: {
					button: action.payload.donation_button_en,
					url: action.payload.donation_url_en,
				},
			}),
		},
		{
			banner: {
				title: '',
				video: '',
				video_mobile: '',
			},
			intro: '',
			semiCtas: [],
			impact: {
				title: '',
				text: '',
				numbers: [],
			},
			image: {
				desktop: '',
				mobile: '',
			},
			fullCta: {},
			ctas: [],
			CTAButton: {
				navi_button: '',
				navi_url: '',
			},
			donateEn: {
				button: '',
				url: '',
			},
		},
	),
};

const selectHome = state => ({
	...state.home,
});

export const useHome = () => useRedux(selectHome);

export default { reducer };
