import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getLangfromURL } from 'util/helper';

import { lanList, LANGUAGE_NAME_MAP } from '../../config/i18n';

import { changeLanguage } from '../models/i18n';

import { getLanguageLocalStorage } from './storage';

export const mapLanguageOption = value => ({
	value,
	label: LANGUAGE_NAME_MAP[value],
});

export const initi18next = store => {
	const lngDetector = new LanguageDetector();

	i18n
		.use(XHR)
		.use(lngDetector)
		.use(initReactI18next)
		.init(
			{
				fallbackLng: lanList[0],
				whitelist: lanList,

				// have a common namespace used around the full app
				ns: ['common'],
				defaultNS: 'common',

				debug: process.env.NODE_ENV !== 'production',

				interpolation: {
					escapeValue: false, // not needed for react!!
				},

				detection: {
					order: ['path', 'localStorage', 'cookie', 'navigator', 'htmlTag'],
					lookupLocalStorage: getLanguageLocalStorage(),
				},

				caches: ['localStorage'],

				react: {
					useSuspense: true,
				},

				backend: {
					loadPath: `/static/resources/{{lng}}/{{ns}}.json`,
				},
			},
			() => {
				if (store) {
					const lan = getLangfromURL();

					i18n.changeLanguage(lan);
				}
			},
		);

	i18n.on('languageChanged', () => {
		if (store) {
			store.dispatch(changeLanguage(i18n.language));
		}
	});

	return i18n;
};

export const changeLng = lng => {
	let lan = lng;

	if (!lanList.includes(lng)) {
		[lan] = lanList;

		console.warn(`${lng} is not in the translation resources`);
	}

	i18n.changeLanguage(lan);
};
