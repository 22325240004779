import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchMethodology = createAction('FETCH_METHODOLOGY', () => async () => {
	const { status, message, data } = await wrapFetch('methodology', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeMethodology = createAction(
	'INITIALIZE_METHODOLOGY',
	() => async dispatch => {
		await dispatch(fetchMethodology());
		await dispatch(setPageLoading());
	},
);

const reducer = {
	methodology: handleActions(
		{
			FETCH_METHODOLOGY_FULFILLED: (state, action) => ({
				...state,
				title: action.payload.title,
				subtitile: action.payload.subtitile,
				titleContent: action.payload.content,
				toolkitCardTitle: action.payload.toolkit_card_title,
				toolkitCardContent: action.payload.toolkit_card_content,
				toolkitCardButton: action.payload.toolkit_card_button,
				toolkitCardPic: action.payload.toolkit_card_pic,
				toolkitCardPicMobile: action.payload.toolkit_card_pic_mobile,
				blocks: action.payload.blocks,
				cta: action.payload.cta,
			}),
		},
		{
			title: '',
			subtitile: '',
			titleContent: '',
			toolkitCardTitle: '',
			toolkitCardContent: '',
			toolkitCardButton: '',
			toolkitCardPic: '',
			toolkitCardPicMobile: '',
			blocks: [],
			cta: [],
		},
	),
};

const selectMethodology = state => ({
	...state.methodology,
});

export const useMethodology = () => useRedux(selectMethodology);

export default { reducer };
