import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchMission = createAction('FETCH_MISSION', () => async () => {
	const { status, message, data } = await wrapFetch('mission', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeMission = createAction('INITIALIZE_MISSION', () => async dispatch => {
	await dispatch(fetchMission());
	await dispatch(setPageLoading());
});

const reducer = {
	mission: handleActions(
		{
			FETCH_MISSION_FULFILLED: (state, action) => ({
				...state,
				title: {
					title: action.payload.banner?.title,
					content: action.payload.banner?.subtitle,
				},
				intro: action.payload.banner?.content,
				button: {
					title: action.payload.banner?.button,
					link: action.payload.banner?.url,
				},
				background: action.payload.banner?.pic,
				ctas: action.payload.cta,
			}),
		},
		{
			title: {
				title: '',
				content: '',
			},
			intro: '',
			button: {
				title: '',
				link: '',
			},
			background: '',
			ctas: [],
		},
	),
};

const selectMission = state => ({
	...state.mission,
});

export const useMission = () => useRedux(selectMission);

export default { reducer };
