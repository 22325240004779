import { setPageLoading } from 'models/common';
import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const fetchBlogType = createAction('FETCH_BLOG_TYPE', () => async () => {
	const { status, message, data } = await wrapFetch('postCategory', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const fetchBlogTypeList = createAction(
	'FETCH_BLOG_TYPE_LIST',
	(type = 1, page = 1, perPage = 18) => async () => {
		const { status, message, data } = await wrapFetch(
			'post',
			{
				method: 'GET',
			},
			{ post_category_url: type, page, per_page: perPage },
		);

		if (status !== 200) {
			throw new Error(message);
		}

		return data;
	},
);

export const setMeta = createAction('SET_META', type => {
	switch (type) {
		case 'all':
			return {
				title: '我們相信，故事的力量｜One-Forty 部落格',
				description:
					'包含移工專訪、議題觀點、活動直擊、團隊故事，透過文章認識 One-Forty、NGO 工作的背後故事，以及移工的生命故事',
			};
		case 'team':
			return {
				title: '團隊故事｜One-Forty 部落格',
				description: '了解非營利組織工作的專業與方法論，以及來自 NGO 工作者第一線的工作心得與分享',
			};
		case 'event':
			return {
				title: '活動直擊｜One-Forty 部落格',
				description:
					'了解 One-Forty 的長期社會行動，透過文章記錄深入移工教育計畫現場、友善東南亞移工的文化交流場域，以及創新的移工議題倡議活動',
			};
		case 'perspective':
			return {
				title: '議題觀點｜One-Forty 部落格',
				description: '完整認識東南亞移工的跨國工作旅程，深入了解台灣的外籍移工議題和移工的生命故事',
			};
		case 'story':
			return {
				title: '移工專訪｜One-Forty 部落格',
				description:
					'透過深度人物專訪，直接聆聽移工的生命故事，看見東南亞移工作為勞動者之外的多元身份和價值',
			};
		default:
			return {
				title: '我們相信，故事的力量｜One-Forty 部落格',
				description:
					'包含移工專訪、議題觀點、活動直擊、團隊故事，透過文章認識 One-Forty、NGO 工作的背後故事，以及移工的生命故事',
			};
	}
});

export const initializeBlogType = createAction(
	'INITIALIZE_BLOG_TYPE',
	(type, page) => async dispatch => {
		await dispatch(fetchBlogType());
		await dispatch(fetchBlogTypeList(type, page));
		await dispatch(setMeta(type));
		await dispatch(setPageLoading());

		return { type, page };
	},
);

const reducer = {
	blog: handleActions(
		{
			FETCH_BLOG_TYPE_FULFILLED: (state, action) => ({
				...state,
				types: action.payload,
			}),
			FETCH_BLOG_TYPE_LIST_FULFILLED: (state, action) => ({
				...state,
				title: action.payload && action.payload.banner.title,
				topBlog: action.payload.top_post,
				blogs: action.payload && action.payload.posts.data,
				totalPage: action.payload && action.payload.posts.meta.last_page,
				ctas: action.payload.cta,
			}),
			SET_META: (state, action) => ({
				...state,
				meta: {
					title: action.payload.title,
					description: action.payload.description,
				},
			}),
			INITIALIZE_BLOG_TYPE_FULFILLED: (state, action) => ({
				...state,
				activeType: action.payload.type,
				activePage: action.payload.page,
			}),
		},
		{
			types: [],
			activeType: '',
			activePage: null,
			title: '',
			topBlog: {},
			blogs: [],
			totalPage: 0,
			ctas: [],
			meta: {
				title: '',
				description: '',
			},
		},
	),
};

const selectBlog = state => ({
	...state.blog,
});

export const useBlog = () => useRedux(selectBlog);

export default { reducer };
