import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeMethodology } from 'models/methodology';

export default {
	path: '/methodology',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'methodology' */ './component')],
			render: ([Methodology]) => <Methodology />,
			onEnter: async ({ store }) => {
				console.log('on Enter methodology');
				await store.dispatch(initializeMethodology());
				await store.dispatch(storeActivePage('methodology'));
				console.log('on Enter methodology / end');
			},
		},
	],
};
