import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeLogoApply } from 'models/logoApply';

export default {
	path: '/logoapply',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'logoapply' */ './component')],
			render: ([LogoApply]) => <LogoApply />,
			onEnter: async ({ store }) => {
				console.log('on Enter logoapply');
				await store.dispatch(initializeLogoApply());
				await store.dispatch(storeActivePage('logoapply'));
				console.log('on Enter logoapply / end');
			},
		},
	],
};
