import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeCareer } from 'models/career';

export default {
	path: '/career',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'career' */ './component')],
			render: ([Career]) => <Career />,
			onEnter: async ({ store }) => {
				console.log('on Enter career');
				await store.dispatch(initializeCareer());
				await store.dispatch(storeActivePage('career'));
				console.log('on Enter career / end');
			},
		},
	],
};
