import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeEvent } from 'models/event';

export default {
	path: '/event',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'event' */ './component')],
			render: ([Event]) => <Event />,
			onEnter: async ({ store }) => {
				console.log('on Enter event');
				await store.dispatch(initializeEvent());
				await store.dispatch(storeActivePage('event'));
				console.log('on Enter event / end');
			},
		},
	],
};
