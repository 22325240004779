import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeVision } from 'models/vision';

export default {
	path: '/vision',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'vision' */ './component')],
			render: ([Vision]) => <Vision />,
			onEnter: async ({ store }) => {
				console.log('on Enter vision');
				await store.dispatch(initializeVision());
				await store.dispatch(storeActivePage('vision'));
				console.log('on Enter vision / end');
			},
		},
	],
};
