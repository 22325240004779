import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeBlockCards } from 'models/blockCards';

export default {
	path: '/blockCards',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'blockCards' */ './component')],
			render: ([BlockCards]) => <BlockCards />,
			onEnter: async ({ store }) => {
				console.log('on Enter blockCards');
				await store.dispatch(initializeBlockCards());
				await store.dispatch(storeActivePage('blockCards'));
				console.log('on Enter blockCards / end');
			},
		},
	],
};
