import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeAvatars } from 'models/avatars';

export default {
	path: '/avatars',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'avatars' */ './component')],
			render: ([Avatars]) => <Avatars />,
			onEnter: async ({ store }) => {
				console.log('on Enter avatars');
				await store.dispatch(initializeAvatars());
				await store.dispatch(storeActivePage('avatars'));
				console.log('on Enter avatars / end');
			},
		},
	],
};
