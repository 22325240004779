import React from 'react';
import classnames from 'classnames';
import { useCommon } from 'models/common';
import Logo from 'components/atoms/Logo';
import Link from 'components/atoms/Link';
import DonateButton from 'components/atoms/DonateButton';
import FacebookIcon from 'images/common/footer/media/facebook.png';
import InstaIcon from 'images/common/footer/media/instagram.png';
import YoutubeIcon from 'images/common/footer/media/youtube.png';
import EmailIcon from 'images/common/footer/media/email.png';
import InfoEmail from 'images/common/footer/info/email.inline.svg';
import InfoPhone from 'images/common/footer/info/phone.inline.svg';
import styles from './index.css';

const Footer = ({ t = {}, className }) => {
	const [{ footerLinks }] = useCommon();

	const medias = [
		{
			id: 1,
			icon: FacebookIcon,
			url: 'https://www.facebook.com/onefortytaiwan',
		},
		{
			id: 2,
			icon: InstaIcon,
			url: 'https://www.instagram.com/onefortytaiwan/',
		},
		{
			id: 3,
			icon: YoutubeIcon,
			url: 'https://www.youtube.com/channel/UCy_DgwI4-K_fjnHpEbwH5sQ',
		},
		{
			id: 4,
			icon: EmailIcon,
			url: 'mailto:contact@one-forty.org',
		},
	];

	return (
		<footer className={classnames(styles.footer, className)}>
			<div className={styles.flex}>
				{t.slogan && (
					<div className={styles.logoWrap}>
						<Link to="/">
							<Logo className={styles.logo} />
						</Link>
						<div className={styles.slogan}>{t.slogan}</div>
					</div>
				)}
				{t.more && (
					<div className={styles.linkWrap}>
						<div className={styles.title}>{t.more}</div>
						<div className={styles.grid}>
							{footerLinks.map(i => (
								<Link url={i.url} className={styles.link} key={i.id}>
									{i.title}
								</Link>
							))}
						</div>
					</div>
				)}
				{t.donate && (
					<div className={styles.donateWrap}>
						<div className={styles.title}>{t.donate.title}</div>
						<div className={styles.text}>{t.donate.detail}</div>
						<DonateButton />
					</div>
				)}
				{t.contact && (
					<div className={styles.contactWrap}>
						<div className={styles.title}>{t.contact.title}</div>
						<div className={styles.mediaWrap}>
							{medias.map(i => (
								<Link
									url={i.url}
									style={{ backgroundImage: `url(${i.icon})` }}
									key={i.id}
									className={styles.icon}
								/>
							))}
						</div>
						<div className={styles.info}>
							<InfoEmail className={styles.icon} />
							{t.contact.email}
						</div>
						<div className={styles.info}>
							<InfoPhone className={styles.icon} />
							{t.contact.number}
						</div>
					</div>
				)}
			</div>
			<div className={styles.rights}>{t.rights}</div>
		</footer>
	);
};

export default Footer;
