import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeDonationInfo } from 'models/donation/info';
import { initializeDonateForm } from 'models/donate/form';

export default {
	path: '/:event_url',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'donationEventUrl' */ './component')],
			render: ([DonationEventUrl]) => <DonationEventUrl />,
			onEnter: async ({ store, params: { event_url } }) => {
				console.log('on Enter donationEventUrl');
				await store.dispatch(initializeDonationInfo(event_url));
				await store.dispatch(initializeDonateForm());
				await store.dispatch(storeActivePage(event_url));
				console.log('on Enter donationEventUrl / end');
			},
		},
	],
};
