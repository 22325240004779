import React from 'react';
import { useHistory, useRouting } from 'models/routing';
import { lanList } from '../../../../config/i18n';

const onClickHandler = (callback, history, newTab) => {
	return e => {
		if (!newTab) {
			e.preventDefault();
			history.push(`${e.currentTarget.pathname}${e.currentTarget.search}`);
		}
		callback(e);
	};
};

const Link = ({
	className,
	style,
	to = '', // internal link
	url = '', // internal or external link
	template = 0, // id for `/project/${id}`
	onClick = () => {},
	onMouseEnter = () => {},
	onmouseleave = () => {},
	children,
	...props
}) => {
	const { SELF_HOST_ENDPOINT } = process.env;
	const history = useHistory();

	const [{ pathname }] = useRouting();
	const lan = pathname.split('/')[1] || 'tw';
	const newTab = url && !(url.indexOf(SELF_HOST_ENDPOINT) === 0 || url.charAt(0) === '/'); // open new tab when url isn't a internal link nor a relative link

	const href = () => {
		switch (true) {
			case Boolean(to):
				return `/${lan}${to}`;
			case Boolean(template):
				return `/${lan}/project/${template}`;
			case url.charAt(0) === '/' && lanList.includes(url.split('/')[1]):
				return `${SELF_HOST_ENDPOINT}${url}`;
			case url.charAt(0) === '/':
				return `/${lan}${url}`;
			default:
				return url;
		}
	};

	return (
		<a
			className={className}
			style={style}
			// eslint-disable-next-line no-nested-ternary
			href={href()}
			target={newTab ? '_blank' : '_self'}
			role="button"
			rel="noreferrer"
			tabIndex={0}
			onClick={onClickHandler(onClick, history, newTab)}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onmouseleave}
			onKeyPress={() => {}}
			/* eslint-disable react/jsx-props-no-spreading */
			{...props}
		>
			{children}
		</a>
	);
};

export default Link;
