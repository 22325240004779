import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchBanner = createAction('FETCH_VISION', () => async () => {
	const { status, message, data } = await wrapFetch('vision', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeBanner = createAction('INITIALIZE_BANNER', () => async dispatch => {
	await dispatch(fetchBanner());
	await dispatch(setPageLoading());
});

const reducer = {
	banner: handleActions(
		{
			FETCH_BANNER_FULFILLED: (state, action) => ({
				...state,
				title: {
					title: action.payload.banner?.title,
					content: action.payload.banner?.subtitle,
				},
				intro: action.payload.banner?.content,
				button: {
					title: action.payload.banner?.button,
					link: action.payload.banner?.url,
				},
				background: action.payload.banner?.pic,
				ctas: action.payload.cta,
			}),
		},
		{
			title: {
				title: '',
				content: '',
			},
			intro: '',
			button: {
				title: '',
				link: '',
			},
			background: '',
			ctas: [],
		},
	),
};

const selectBanner = state => ({
	...state.banner,
});

export const useBanner = () => useRedux(selectBanner);

export default { reducer };
