import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeImpactReport } from 'models/impactReport';

export default {
	path: '/impact-report',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'impactReport' */ './component')],
			render: ([ImpactReport]) => <ImpactReport />,
			onEnter: async ({ store }) => {
				console.log('on Enter impact-report');
				await store.dispatch(initializeImpactReport());
				await store.dispatch(storeActivePage('impact-report'));
				console.log('on Enter impact-report / end');
			},
		},
	],
};
