import { setPageLoading } from 'models/common';
import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const fetchDonateInfo = createAction('FETCH_DONATE_INFO', () => async () => {
	const { status, message, data } = await wrapFetch('donation', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeDonateInfo = createAction('INITIALIZE_DONATE_INFO', () => async dispatch => {
	await dispatch(fetchDonateInfo());
	await dispatch(setPageLoading());
});

const reducer = {
	donateInfo: handleActions(
		{
			FETCH_DONATE_INFO_FULFILLED: (state, action) => ({
				...state,
				banner: action.payload.banner,
				quotes: action.payload.messages,
				faqs: action.payload.questions,
			}),
		},
		{
			banner: {},
			quotes: [],
			faqs: [],
		},
	),
};

const selectDonateInfo = state => ({
	...state.donateInfo,
});

export const useDonateInfo = () => useRedux(selectDonateInfo);

export default { reducer };
