import React from 'react';
import { storeActivePage } from 'models/common';
import { initializePageDetail } from 'models/page/pageDetail';

export default {
	path: '/:id',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'pageDetail' */ './component')],
			render: ([PageDetail]) => <PageDetail />,
			onEnter: async ({ store, params: { id } }) => {
				console.log('on Enter pageDetail');
				await store.dispatch(initializePageDetail(id));
				await store.dispatch(storeActivePage(`page_${id}`));
				console.log('on Enter pageDetail / end');
			},
		},
	],
};
