import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchCampaign = createAction('FETCH_CAMPAIGN', () => async () => {
	const { status, message, data } = await wrapFetch('plan/advocacy/annual', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeCampaign = createAction('INITIALIZE_CAMPAIGN', () => async dispatch => {
	await dispatch(fetchCampaign());
	await dispatch(setPageLoading());
});

const reducer = {
	campaign: handleActions(
		{
			FETCH_CAMPAIGN_FULFILLED: (state, action) => ({
				...state,
				title: action.payload.banner,
				intro: action.payload.content,
				links: action.payload.annuals,
				ctas: action.payload.cta,
			}),
		},
		{
			title: {},
			intro: '',
			links: [],
			ctas: [],
		},
	),
};

const selectCampaign = state => ({
	...state.campaign,
});

export const useCampaign = () => useRedux(selectCampaign);

export default { reducer };
