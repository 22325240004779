import { setPageLoading } from 'models/common';
import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const fetchWelcome = createAction('FETCH_DONATE_WELCOME', () => async () => {
	const { status, message, data } = await wrapFetch('donation/thank', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeDonateWelcome = createAction(
	'INITIALIZE_DONATE_WELCOME',
	() => async dispatch => {
		await dispatch(fetchWelcome());
		await dispatch(setPageLoading());
	},
);

const reducer = {
	donateWelcome: handleActions(
		{
			FETCH_DONATE_WELCOME_FULFILLED: (state, action) => ({
				...state,
				title: action.payload.title,
				intro: action.payload.content,
				subtitle: action.payload.subtitle,
				topics: action.payload.cards,
				questionnnaire: action.payload.question,
				share: { ...action.payload.share, style: 'RIGHT_WHITE' },
			}),
		},
		{
			title: '',
			intro: [],
			topics: [],
			questionnnaire: {
				button: '',
				content: '',
				pic: '',
				pic_mobile: '',
				title: '',
				url: '',
			},
			share: {
				content: '',
				pic: '',
				pic_mobile: '',
				title: '',
			},
		},
	),
};

const selectDonateWelcome = state => ({
	...state.donateWelcome,
});

export const useDonateWelcome = () => useRedux(selectDonateWelcome);

export default { reducer };
