import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeTextModules } from 'models/textModules';

export default {
	path: '/textModules',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'textModules' */ './component')],
			render: ([TextModules]) => <TextModules />,
			onEnter: async ({ store }) => {
				console.log('on Enter textModules');
				await store.dispatch(initializeTextModules());
				await store.dispatch(storeActivePage('textModules'));
				console.log('on Enter textModules / end');
			},
		},
	],
};
