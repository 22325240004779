export default {
	path: '',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '/2015/05/30/harirayaidilfitri_trip',
			onEnter: () => {
				window.location.replace('/tw/blog/lebaran-trip');
			},
			render: 'ridirect',
		},
		{
			path: '/2015/07/11/migrantbusinesschool_1',
			onEnter: () => {
				window.location.replace('/tw/blog/migrant-campaign');
			},
			render: 'ridirect',
		},
		{
			path: '/tw/impact',
			onEnter: () => {
				window.location.replace('/tw/impact/report');
			},
			render: 'redirect',
		},
	],
};
