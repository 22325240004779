import { createAction, handleActions } from 'redux-actions';
import { wrapFetch, wrapLangFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { useTrackingCode } from 'react-hubspot-tracking-code-hook';
import storage from 'util/storage';
import { seoDataSchema } from './schema';

export const storeActivePage = createAction('STORE_ACTIVE_PAGE', page => page);

export const createFetchSeoAction = createAction('FETCH_SEO', () => async () => {
	// !! 直接依照瀏覽器當前環境指向『路徑 location.pathname』打 API
	const { status, message, data } = await wrapFetch('seo', {}, { url: location.pathname });

	// FIXME: workaround 寫死本地開發判斷
	const currentUrl =
		location.hostname === 'localhost'
			? 'https://oneforty.kiitzu.ninja' + location.pathname
			: location.origin + location.pathname;

	if (status !== 200) {
		throw new Error(message);
	}

	if (!seoDataSchema.safeParse(data).success) {
		throw new Error('seo schema invalid');
	}

	return {
		isFallback: data.url !== currentUrl,
		property: data,
	};
});

export const fetchNav = createAction('FETCH_NAV', () => async () => {
	const { status, message, data } = await wrapFetch('nav');

	if (!(data?.header instanceof Array)) {
		throw new Error('header undefined');
	}

	return data.header;
});

export const fetchHeader = createAction('FETCH_HEADER', () => async () => {
	const { data } = await wrapFetch(`header`);
	if (!(data?.impacts instanceof Array)) {
		// console.error('fetch header fail.');
	}

	// workaround
	data.impacts = data.impacts.map(item => ({ name: item.title, link: `impact/${item.url}` }));

	return data;
});

export const fetchFooter = createAction('FETCH_FOOTER', () => async () => {
	const { status, message, data } = await wrapFetch('footer', { method: 'GET' });

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const setHubspotTrackingEmail = createAction('SET_HUBSPOT_TRACKING_EMAIL', email => {
	const { setIdentity } = useTrackingCode();

	setIdentity(email);
	storage.setItem('hubspot-tracking-email', email);
});

export const setShowMailchimpSuccess = createAction('SET_SHOW_MAILCHIMP_SUCCESS', (tag, value) => {
	return { tag, value };
});

export const setShowMailchimpError = createAction('SET_SHOW_MAILCHIMP_ERROR', (tag, value) => {
	return { tag, value };
});

export const fetchMailchimp = createAction('FETCH_MAILCHIMP', (email, tag) => async dispatch => {
	const body = {
		email,
		tags: [tag],
	};

	const { status, message, data } = await wrapFetch('hubSpot/mailSubscribe', {
		method: 'POST',
		body: JSON.stringify(body),
	});

	if (status !== 200) {
		dispatch(setShowMailchimpError(tag, true));
		throw new Error(message);
	} else {
		dispatch(setShowMailchimpSuccess(tag, true));
		dispatch(setHubspotTrackingEmail(email));
	}

	return data;
});

export const fetchCTA = createAction('FETCH_CTA', () => async () => {
	const { status, message, data } = await wrapLangFetch('homepage', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const setPageLoading = createAction('SET_PAGE_LOADING', () => false);

export const initializeCommon = createAction('INITIALIZE_COMMON', () => async dispatch => {
	await dispatch(createFetchSeoAction());
	await dispatch(fetchNav());
	await dispatch(fetchHeader());
	await dispatch(fetchFooter());
	await dispatch(fetchCTA());
	await dispatch(setHubspotTrackingEmail(storage.getItem('hubspot-tracking-email') || ''));
});

const reducer = {
	common: handleActions(
		{
			STORE_ACTIVE_PAGE: (state, action) => ({
				...state,
				activePage: action.payload,
			}),
			FETCH_SEO_FULFILLED: (state, action) => ({
				...state,
				seo: action.payload,
			}),
			FETCH_NAV_FULFILLED: (state, action) => ({
				...state,
				navLinks: action.payload,
			}),
			FETCH_HEADER_FULFILLED: (state, action) => ({
				...state,
				headerLinks: action.payload,
			}),
			FETCH_FOOTER_FULFILLED: (state, action) => ({
				...state,
				footerLinks: action.payload.learns,
			}),
			SET_SHOW_MAILCHIMP_SUCCESS: (state, action) => ({
				...state,
				showMailchimpSuccess: {
					[action.payload.tag]: action.payload.value,
				},
			}),
			SET_SHOW_MAILCHIMP_ERROR: (state, action) => ({
				...state,
				showMailchimpError: {
					[action.payload.tag]: action.payload.value,
				},
			}),
			FETCH_MAILCHIMP_FULFILLED: (state, action) => ({
				...state,
				mailchimpSigned: action.payload,
			}),
			SET_PAGE_LOADING: (state, action) => ({
				...state,
				isLoading: action.payload,
			}),
			FETCH_CTA_FULFILLED: (state, action) => {
				// 加入判斷不然當你捐款成功回到首頁會連call兩次(bug)
				if (action.payload === undefined) {
					return state;
				} else {
					return {
						...state,
						CTAButton: {
							navi_button: action.payload.navi_button,
							navi_url: action.payload.navi_url,
						},
					};
				}
			},
		},
		{
			activePage: '',
			headerLinks: [],
			footerLinks: [],
			showMailchimpSuccess: {},
			showMailchimpError: {},
			mailchimpSigned: {},
			isLoading: true,
			CTAButton: {
				navi_button: '',
				navi_url: '',
			},
		},
	),
};

const selectCommon = state => ({
	...state.common,
});

export const useCommon = () =>
	useRedux(selectCommon, { setShowMailchimpSuccess, setShowMailchimpError, fetchMailchimp });

export default { reducer };
