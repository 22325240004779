import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchEducationProgram = createAction('FETCH_EDUCATION_PROGRAM', () => async () => {
	const { status, message, data } = await wrapFetch('plan/education', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeEducationProgram = createAction(
	'INITIALIZE_EDUCATION_PROGRAM',
	() => async dispatch => {
		await dispatch(fetchEducationProgram());
		await dispatch(setPageLoading());
	},
);

const reducer = {
	educationProgram: handleActions(
		{
			FETCH_EDUCATION_PROGRAM_FULFILLED: (state, action) => ({
				...state,
				banner: action.payload.banner,
				intro:
					typeof action.payload.content === 'string'
						? [{ type: 'string', content: action.payload.content }]
						: action.payload.content,
				topics: action.payload.cards,
				cards: action.payload.blocks,
				numbers: action.payload.numbers,
				student: action.payload.student,
				ctas: action.payload.cta,
			}),
		},
		{
			banner: {},
			intro: [],
			topics: [],
			cards: [],
			numbers: [],
			student: {},
			ctas: [],
		},
	),
};

const selectEducationProgram = state => ({
	...state.educationProgram,
});

export const useEducationProgram = () => useRedux(selectEducationProgram);

export default { reducer };
