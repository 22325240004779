import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchVideo = createAction('FETCH_VIDEO', () => async () => {
	const { status, message, data } = await wrapFetch('plan/advocacy/video', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeVideo = createAction('INITIALIZE_VIDEO', () => async dispatch => {
	await dispatch(fetchVideo());
	await dispatch(setPageLoading());
});

const reducer = {
	video: handleActions(
		{
			FETCH_VIDEO_FULFILLED: (state, action) => ({
				...state,
				title: action.payload.banner,
				intro:
					typeof action.payload.content === 'string'
						? [{ type: 'string', content: action.payload.content }]
						: action.payload.content,
				videos: action.payload.videos,
				ctas: action.payload.cta,
			}),
		},
		{
			title: {},
			intro: [],
			videos: [],
			ctas: [],
		},
	),
};

const selectVideo = state => ({
	...state.video,
});

export const useVideo = () => useRedux(selectVideo);

export default { reducer };
