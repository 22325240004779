import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeBlogType } from 'models/blog/blogType';

export default {
	path: '/blog-type/:type/:page',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'blogTypeDetail' */ './component')],
			render: ([BlogTypeDetail]) => <BlogTypeDetail />,
			onEnter: async ({ store, params: { type, page } }) => {
				console.log('on Enter blogTypeDetail');
				await store.dispatch(initializeBlogType(type, +page));
				await store.dispatch(storeActivePage(`blog-type_${type}_${page}`));
				console.log('on Enter blogTypeDetail / end');
			},
		},
	],
};
