import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeMission } from 'models/mission';

export default {
	path: '/mission',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'mission' */ './component')],
			render: ([Mission]) => <Mission />,
			onEnter: async ({ store }) => {
				console.log('on Enter mission');
				await store.dispatch(initializeMission());
				await store.dispatch(storeActivePage('mission'));
				console.log('on Enter mission / end');
			},
		},
	],
};
