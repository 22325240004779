import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchTeam = createAction('FETCH_TEAM', () => async () => {
	const { status, message, data } = await wrapFetch('team', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeTeam = createAction('INITIALIZE_TEAM', () => async dispatch => {
	await dispatch(fetchTeam());
	await dispatch(setPageLoading());
});

const reducer = {
	team: handleActions(
		{
			FETCH_TEAM_FULFILLED: (state, action) => ({
				...state,
				banner: action.payload.banner,
				teams: action.payload.teams,
				cta: action.payload.cta,
			}),
		},
		{
			banner: {},
			teams: [],
			cta: [],
		},
	),
};

const selectTeam = state => ({
	...state.team,
});

export const useTeam = () => useRedux(selectTeam);

export default { reducer };
