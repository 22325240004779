import React from 'react';
import { MenuIcon, CloseIcon } from 'components/atoms/Icon';
import cn from 'classnames';
import styles from './index.css';

function MenuToggleButton({ active, className, ...props }) {
	return (
		<button type="button" className={cn(styles.root, className)} {...props}>
			{active ? <CloseIcon /> : <MenuIcon />}
		</button>
	);
}

export { MenuToggleButton };
