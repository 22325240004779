import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeAppAccountDeletion } from 'models/appAccountDeletion';

export default {
	path: '/app-account-deletion',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'appAccountDeletion' */ './component')],
			render: ([AppAccountDeletion]) => <AppAccountDeletion />,
			onEnter: async ({ store }) => {
				console.log('on Enter appAccountDeletion');
				await store.dispatch(initializeAppAccountDeletion());
				await store.dispatch(storeActivePage('appAccountDeletion'));
				console.log('on Enter appAccountDeletion / end');
			},
		},
	],
};
