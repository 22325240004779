import React from 'react';
import SEO from 'components/atoms/SEO';
import { useCommon } from 'models/common';

const initialSeoContext = {
	isFallback: true,
	SeoController,
};

const SeoContext = React.createContext({
	...initialSeoContext,
});

// !! SEO 模組設定 > 各頁面單獨設定 SEO
// !! 各頁面若本身有 SEO 只會在 SEO 模組沒有設定的情況下套用，SEO 模組有設定則以此為主
function SeoProvider({ children }) {
	const [commonData] = useCommon();
	// seo 資料結構請參考 model/common/schema.js
	const seo = commonData?.seo;

	const shared = {
		isFallback: seo.isFallback,
		SeoController,
	};

	return (
		<SeoContext.Provider value={shared}>
			{children}
			<SEO
				url={seo.property.url}
				metaTitle={seo.property.meta_title}
				metaDescription={seo.property.meta_description}
				metaImage={seo.property.meta_image}
				ogTitle={seo.property.og_title}
				ogDescription={seo.property.og_description}
				ogImage={seo.property.og_image}
				jsonLd={seo.property.json_ld}
			/>
		</SeoContext.Provider>
	);
}

// return SEO component
function useSeoContext() {
	const context = React.useContext(SeoContext);

	if (!context) {
		throw new Error('useSeoContext has to be used within <SeoProvider>');
	}

	return context;
}

// Seo 預期為『設定好之 SEO 元件』 傳入，由於 SEO 由 helmet 套件實作，相同屬性 meta 會進行覆蓋，所以用 isFallback 狀態控制是否放行
// example: <SeoController Seo={() => <SEO url="" ... />} />
// !! 注意 Render 必須為大寫開頭，小寫開頭似乎會被 babel 編譯器過濾成非 JSX 語法，導致元素沒辦法正常渲染
function SeoController({ Render }) {
	const { isFallback } = useSeoContext();

	if (!isFallback || !Render) return null;

	return <Render />;
}

export { SeoProvider, useSeoContext, SeoController };
