import React from 'react';
import { NavItem, NavItemVariant } from 'components/atoms/NavItem';
import { NavMenu, NavVariant } from 'components/molecules/NavMenu';
import cn from 'classnames';
import styles from './index.css';

// type NavItem = {
// 	id: number;
// 	type?: 'ONE_COLUMN' | 'TWO_COLUMNS' | 'LINK' | 'DONATE_LINK',
// 	name: string;
// 	link: string;
// 	hightlight?: 'Y' | 'N',
// 	sub?: NavItem[]
// }

// const NavVariant = {
// 	ONE_COLUMN: 'ONE_COLUMN',
// 	TWO_COLUMNS: 'TWO_COLUMNS',
// 	LINK: 'LINK',
// 	DONATE_LINK: 'DONATE_LINK',
// };

// const navL1: NavItem;

// !! NavItemVariant 是前端依據設計稿進行區分／NavVariant 是根據 API 資料給的區分（後端定義）
// 目前只依據 L1 type 處理（API 只有第一層會給 type）
// issue 1: 不確定原因，css module 在 switch 中綁定元素時，樣式似乎會綁不上去
function NavL1Controller({ navL1, className, activeL1 = false, ...props }) {
	switch (navL1?.type) {
		case NavVariant.ONE_COLUMN:
			return (
				<div className={cn(styles.root)}>
					<NavItem
						variant={NavItemVariant.TRIGGER_L1}
						active={activeL1}
						className={cn(styles.navL1, className)}
						{...props}
					>
						{navL1.name}
					</NavItem>
					<NavMenu
						navL2={navL1?.sub ?? []}
						variant={NavVariant.ONE_COLUMN}
						activeL1={activeL1}
						className={styles.navL2}
					/>
				</div>
			);
		case NavVariant.TWO_COLUMNS:
			return (
				<div className={cn(styles.root)}>
					<NavItem
						variant={NavItemVariant.TRIGGER_L1}
						active={activeL1}
						className={cn(styles.navL1, className)}
						{...props}
					>
						{navL1.name}
					</NavItem>
					<NavMenu
						navL2={navL1?.sub ?? []}
						variant={NavVariant.TWO_COLUMNS}
						activeL1={activeL1}
						className={styles.navL2}
					/>
				</div>
			);
		case NavVariant.LINK:
			return (
				<div className={cn(styles.root)}>
					<NavItem
						variant={NavItemVariant.LINK_L1}
						href={navL1.link}
						className={className}
						{...props}
					>
						{navL1.name}
					</NavItem>
				</div>
			);
		case NavVariant.DONATE_LINK:
			return (
				<div className={cn(styles.root)}>
					<NavItem
						variant={NavItemVariant.DONATE_LINK_L1}
						href={navL1.link}
						className={className}
						{...props}
					>
						{navL1.name}
					</NavItem>
				</div>
			);
		default:
			return null;
	}
}

export { NavL1Controller, NavItemVariant, NavVariant };
