import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchAdvocacyProgram = createAction('FETCH_ADVOCACY_PROGRAM', () => async () => {
	const { status, message, data } = await wrapFetch('plan/advocacy', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeAdvocacyProgram = createAction(
	'INITIALIZE_ADVOCACY_PROGRAM',
	() => async dispatch => {
		await dispatch(fetchAdvocacyProgram());
		await dispatch(setPageLoading());
	},
);

const reducer = {
	advocacyProgram: handleActions(
		{
			FETCH_ADVOCACY_PROGRAM_FULFILLED: (state, action) => ({
				...state,
				banner: action.payload.banner,
				intro:
					typeof action.payload.content === 'string'
						? [{ type: 'string', content: action.payload.content }]
						: action.payload.content,
				cards: action.payload.blocks,
				numbers: action.payload.numbers,
				ctas: action.payload.cta,
			}),
		},
		{
			banner: {},
			intro: [],
			cards: [],
			numbers: [],
			ctas: [],
		},
	),
};

const selectAdvocacyProgram = state => ({
	...state.advocacyProgram,
});

export const useAdvocacyProgram = () => useRedux(selectAdvocacyProgram);

export default { reducer };
