import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeImpactEvent } from 'models/impact/event';

export default {
	path: '/:event',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'impact' */ './component')],
			render: ([ImpactEvent]) => <ImpactEvent />,
			onEnter: async ({ store, params: { event } }) => {
				console.log('on Enter impactEvent.js');
				await store.dispatch(initializeImpactEvent(event));
				await store.dispatch(storeActivePage(event));
				console.log('on Enter impactEvent.js / end');
			},
		},
	],
};
