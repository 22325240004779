import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeEducationProgram } from 'models/educationProgram';

export default {
	path: '/education-program',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'educationProgram' */ './component')],
			render: ([EducationProgram]) => <EducationProgram />,
			onEnter: async ({ store }) => {
				console.log('on Enter education-program');
				await store.dispatch(storeActivePage('education-program'));
				await store.dispatch(initializeEducationProgram());
				console.log('on Enter education-program / end');
			},
		},
	],
};
