import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeDonateReceipt } from 'models/donate/receipt';
import { initializeDonateInfo } from 'models/donate/info';
import { getParameterByName } from 'util/helper';

export default {
	path: '/success',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'donateSuccess' */ './component')],
			render: ([DonateSuccess]) => <DonateSuccess />,
			onEnter: async ({ store }) => {
				console.log('on Enter donate success');
				const orderId = getParameterByName('order_number');
				await store.dispatch(initializeDonateReceipt(orderId));
				await store.dispatch(initializeDonateInfo());
				await store.dispatch(storeActivePage('donate/success'));
				console.log('on Enter donate success / end');
			},
		},
	],
};
