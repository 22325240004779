import React from 'react';
import { storeActivePage } from 'models/common';
import { initializePicModules } from 'models/picModules';

export default {
	path: '/picmodules',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'picmodules' */ './component')],
			render: ([PicModules]) => <PicModules />,
			onEnter: async ({ store }) => {
				console.log('on Enter picmodules');
				await store.dispatch(initializePicModules());
				await store.dispatch(storeActivePage('picmodules'));
				console.log('on Enter picmodules / end');
			},
		},
	],
};
