import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeAdvocacyProgram } from 'models/advocacyProgram';

export default {
	path: '/advocacy-program',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'advocacyProgram' */ './component')],
			render: ([AdvocacyProgram]) => <AdvocacyProgram />,
			onEnter: async ({ store }) => {
				console.log('on Enter advocacy-program');
				await store.dispatch(storeActivePage('advocacy-program'));
				await store.dispatch(initializeAdvocacyProgram());
				console.log('on Enter advocacy-program / end');
			},
		},
	],
};
