import React from 'react';

// v5 24x24
function MenuIcon(props) {
	return (
		<svg
			data-name="navigation/menu_24px"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			{...props}
		>
			<path style={{ fill: 'none' }} d="M0 0h24v24H0z" />
			<path data-name="↳Color" d="M21 18H3v-2h18v2zm0-5H3v-2h18v2zm0-5H3V6h18v2z" />
		</svg>
	);
}

export { MenuIcon };
