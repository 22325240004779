import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';

export const storeController = createAction('STORE_CONTROLLER', (page, type, controller) => ({
	page,
	type,
	controller,
}));

export const abortController = createAction(
	'ABORT_CONTROLLER',
	(page, type) => async (dispatch, getState) => {
		const {
			controllers: { [page]: pageControllers },
		} = getState();

		const oldController = pageControllers[type];
		oldController.abort();
		return { page, type };
	},
);

const reducer = {
	controllers: handleActions(
		{
			STORE_CONTROLLER: (state, action) => ({
				...state,

				[action.payload.page]: {
					...state[action.payload.page],
					[action.payload.type]: action.payload.controller,
				},
			}),

			ABORT_CONTROLLER: (state, action) => ({
				...state,

				[action.payload.page]: {
					...state[action.payload.page],
					[action.payload.type]: undefined,
				},
			}),
		},
		{
			brandsDetail: {},
			brandsMenu: {},
			brandsMenuUpdata: {},
			blogUpdata: {},
			seo: {},
		},
	),
};

const selectControllers = state => ({
	...state.controllers,
});

export const useControllers = () =>
	useRedux(selectControllers, {
		storeController,
		abortController,
	});

export default { reducer };
