import React from 'react';

function Logo({ className, ...props }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="76"
			height="28.795"
			viewBox="0 0 76 30"
			className={className}
			{...props}
		>
			<g data-name="Navigation Bar＿logo">
				<path
					data-name="Path 2888"
					d="M241.513 118.167a3.581 3.581 0 0 0-7.15.3v8.713l-5.114-7.115-2.3-3.484a3.443 3.443 0 0 0-.195-.267l-.038-.053a3.581 3.581 0 0 0-6.315 1.869h-.026v20.8a3.583 3.583 0 1 0 7.165 0v-8.53l7.354 10.413v-.013a3.58 3.58 0 0 0 6.637-1.866v-20.767z"
					style={{ fill: '#fcc80e' }}
					transform="translate(-189.721 -114.381)"
				/>
				<path
					data-name="Path 2889"
					d="M262.133 135.652h-10.616v-3.727h11.255a2.948 2.948 0 1 0 0-5.9h-11.255V122.3h10.616a3.588 3.588 0 1 0 0-7.176H247.868a3.653 3.653 0 0 0-3.653 3.653v20.4a3.653 3.653 0 0 0 3.252 3.631v.022h14.671a3.588 3.588 0 1 0 0-7.176z"
					style={{ fill: '#fcc80e' }}
					transform="translate(-189.721 -114.381)"
				/>
				<g data-name="Group 601">
					<path
						data-name="Path 2890"
						d="M218.236 128.776c0 7.95-5.688 14.6-14.258 14.4a14.528 14.528 0 0 1-14.257-14.4c0-7.1 5.969-14.359 14.257-14.4a14.291 14.291 0 0 1 14.258 14.4z"
						style={{ fill: '#fcc80e' }}
						transform="translate(-189.721 -114.381)"
					/>
					<g data-name="Group 600">
						<g data-name="Group 599">
							<path
								data-name="Path 2891"
								d="M202.213 133.44a1.61 1.61 0 0 1-.394 1.174 1.264 1.264 0 0 1-.94.406 1.245 1.245 0 0 1-.925-.407 1.624 1.624 0 0 1-.394-1.176v-2.72h-4.085q-1.319 0-1.317-1.6v-4a1.614 1.614 0 0 1 .393-1.172 1.248 1.248 0 0 1 .924-.4 1.271 1.271 0 0 1 .941.4 1.619 1.619 0 0 1 .394 1.172v2.946h2.75v-2.946a1.619 1.619 0 0 1 .394-1.172 1.257 1.257 0 0 1 .925-.4 1.273 1.273 0 0 1 .94.4 1.621 1.621 0 0 1 .394 1.174z"
								style={{ fill: '#1a96d5' }}
								transform="translate(-189.721 -114.381)"
							/>
						</g>
						<path
							data-name="Path 2892"
							d="M209.049 123.534a5.768 5.768 0 1 0 5.284 5.749 5.532 5.532 0 0 0-5.284-5.749zm0 8.582a2.851 2.851 0 1 1 2.538-2.834 2.674 2.674 0 0 1-2.539 2.834z"
							style={{ fill: '#1a96d5' }}
							transform="translate(-189.721 -114.381)"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default Logo;
