import React from 'react';
import { storeActivePage } from 'models/common';
import { initializeVideo } from 'models/video';

export default {
	path: '/video',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'video' */ './component')],
			render: ([Video]) => <Video />,
			onEnter: async ({ store }) => {
				console.log('on Enter video');
				await store.dispatch(initializeVideo());
				await store.dispatch(storeActivePage('video'));
				console.log('on Enter video / end');
			},
		},
	],
};
