import { createAction } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const initializeAppAccountDeletion = createAction(
	'INITIALIZE_APP_ACCOUNT_DELETION',
	() => async dispatch => {
		await dispatch(setPageLoading());
	},
);

const selectAppAccountDeletion = state => ({
	...state.appAccountDeletion,
});

export const useAppAccountDeletion = () => useRedux(selectAppAccountDeletion);
