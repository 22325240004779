import { createAction, handleActions } from 'redux-actions';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { setPageLoading } from './common';

export const fetchEvent = createAction('FETCH_EVENT', () => async () => {
	const { status, message, data } = await wrapFetch('plan/advocacy/monthly', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const initializeEvent = createAction('INITIALIZE_EVENT', () => async dispatch => {
	await dispatch(fetchEvent());
	await dispatch(setPageLoading());
});

const reducer = {
	event: handleActions(
		{
			FETCH_EVENT_FULFILLED: (state, action) => ({
				...state,
				title: action.payload.banner,
				intro: action.payload.content,
				links: action.payload.monthlies,
				ctas: action.payload.cta,
			}),
		},
		{
			title: {},
			intro: '',
			links: [],
			ctas: [],
		},
	),
};

const selectEvent = state => ({
	...state.event,
});

export const useEvent = () => useRedux(selectEvent);

export default { reducer };
